

import { BackSide, ColorRepresentation, DoubleSide, MathUtils, FrontSide, Mesh, MeshBasicMaterial, Object3D, RepeatWrapping, Side } from "three";
import { createSquareGeo, createSquareGeoWithUv, createTexture, appendChild } from "./three-utils/utils";


export function createVideoBoard(color: ColorRepresentation) {

  const maxX = 40.6, minX = -106

  const maxY = 18;
  const minY = -40;

  const minZ = 110;

  const planeGeo = createSquareGeoWithUv(
    [
      [minX, maxY, minZ],//左上
      [maxX, maxY, minZ],//右上
      [minX, minY, minZ],//左下

      [maxX, minY, minZ],//右下
      [minX, minY, minZ],//左下
      [maxX, maxY, minZ],//右上
    ],
    [
      [0.0, 1.0],
      [1.0, 1.0],
      [0.0, 0.0],

      [1.0, 0.0],
      [0.0, 0.0],
      [1.0, 1.0],
    ]
  );

  return createTexture('texs/test.jpg').then(tex => {

    tex.wrapS = 1000
    tex.wrapT = 1000;
    tex.encoding = 3001;
    // tex.flipY = false;
    return new Mesh(planeGeo, new MeshBasicMaterial({ side: FrontSide, map: tex }))
  })


}


export function createVideoPoard2() {

  // const maxX = 44.2, minX = 26 
  // const maxY = 17.6;
  // const minY = 5.8;

  const leftStart = -37.4, upStart = 24.4;

  const maxX = leftStart, minX = leftStart - 63.6;
  const maxY = upStart;
  const minY = upStart - 45.4;

  const minZ = -90;

  const planeGeo = createSquareGeoWithUv(
    [
      [maxX, maxY, minZ],
      [minX, maxY, minZ],
      [maxX, minY, minZ],

      [minX, minY, minZ],
      [maxX, minY, minZ],
      [minX, maxY, minZ],
    ],
    [
      [0.0, 1.0],
      [1.0, 1.0],
      [0.0, 0.0],

      [1.0, 0.0],
      [0.0, 0.0],
      [1.0, 1.0],
    ]
  );

  return createTexture('texs/test.jpg').then(tex => {
    tex.encoding = 3001;
    return new Mesh(planeGeo, new MeshBasicMaterial({ side: FrontSide, map: tex }))
  })

}


const uvs: [number, number][] = [
  [0.0, 1.0],
  [1.0, 1.0],
  [0.0, 0.0],

  [1.0, 0.0],
  [0.0, 0.0],
  [1.0, 1.0],
]


export function createPoard(xStart: number, xLength: number, yStart: number, yLenght: number, side: Side, z: number = -90) {

  const leftStart = xStart

  const maxX = leftStart, minX = leftStart - xLength;
  const maxY = yStart;
  const minY = yStart - yLenght;

  const minZ = z;

  const planeGeo = createSquareGeoWithUv(
    [
      [maxX, maxY, minZ],
      [minX, maxY, minZ],
      [maxX, minY, minZ],

      [minX, minY, minZ],
      [maxX, minY, minZ],
      [minX, maxY, minZ],
    ],
    uvs
  );

  return createTexture('texs/test.jpg').then(tex => {
    tex.encoding = 3001;
    return new Mesh(planeGeo, new MeshBasicMaterial({ side: side, map: tex }))
  })

}

const rotateY90 = (obj3d: Object3D) => { obj3d.rotateY(MathUtils.degToRad(90)); return obj3d }

export const createStartComponyBoard = () => createPoard(-4, 43, 31.0, 24.7, FrontSide, -90)
  .then(rotateY90)

export const createCloseComponyBoard = () => createPoard(43, 43, 31.0, 24.7, FrontSide, -90)
  .then(rotateY90)

export const createFoodAndDrugBoard = () => createPoard(35.6, 82, 21, 37, FrontSide, -90)
  .then(rotateY90)

export const createFoodAndDrugOperaBoard = () => createPoard(135, 100, 27.6, 57, DoubleSide, -90)



export function createPicPoard2() {

  // const maxX = 44.2, minX = 26 
  // const maxY = 17.6;
  // const minY = 5.8;

  const leftStart = 38.2, upStart = 24.4;

  const maxX = leftStart, minX = leftStart - 64;
  const maxY = upStart;
  const minY = upStart - 45.4;

  const minZ = -90;

  const planeGeo = createSquareGeoWithUv(
    [
      [maxX, maxY, minZ],
      [minX, maxY, minZ],
      [maxX, minY, minZ],

      [minX, minY, minZ],
      [maxX, minY, minZ],
      [minX, maxY, minZ],
    ],
    [
      [0.0, 1.0],
      [1.0, 1.0],
      [0.0, 0.0],

      [1.0, 0.0],
      [0.0, 0.0],
      [1.0, 1.0],
    ]
  );

  return createTexture('texs/test.jpg').then(tex => {
    tex.encoding = 3001;
    return new Mesh(planeGeo, new MeshBasicMaterial({ side: FrontSide, map: tex }))
  })

}



function createOnePicPoard(leftStart: number, upStart: number) {

  // const maxX = 44.2, minX = 26 
  // const maxY = 17.6;
  // const minY = 5.8;

  const maxX = leftStart, minX = leftStart - 18.2;
  const maxY = upStart;
  const minY = upStart - 11.8;

  const minZ = 100;

  const planeGeo = createSquareGeoWithUv(
    [
      [maxX, maxY, minZ],
      [minX, maxY, minZ],
      [maxX, minY, minZ],

      [minX, minY, minZ],
      [maxX, minY, minZ],
      [minX, maxY, minZ],
    ],
    [
      [0.0, 1.0],
      [1.0, 1.0],
      [0.0, 0.0],

      [1.0, 0.0],
      [0.0, 0.0],
      [1.0, 1.0],
    ]
  );

  return createTexture('texs/test.jpg').then(tex => {
    tex.encoding = 3001;
    return new Mesh(planeGeo, new MeshBasicMaterial({ side: BackSide, map: tex }))
  })

}


export function createPicsBoards(color: ColorRepresentation) {

  return Promise.all([
    createOnePicPoard(44.2, 17.6),
    createOnePicPoard(44.2 - 37.1, 17.6),
    createOnePicPoard(44.2 - 37.1 * 2, 17.6),

    createOnePicPoard(44.2, 17.6 - 17.2),
    createOnePicPoard(44.2 - 37.1, 17.6 - 17.2),
    createOnePicPoard(44.2 - 37.1 * 2, 17.6 - 17.2),

    createOnePicPoard(44.2, 17.6 - 17.2 * 2 + 0.6),
    createOnePicPoard(44.2 - 37.1, 17.6 - 17.2 * 2 + 0.6),
    createOnePicPoard(44.2 - 37.1 * 2, 17.6 - 17.2 * 2 + 0.6),
  ]
  ).then(objs => new Object3D().add(...objs))

}

export function createLeanBoard(start: number, downLeftDiff: number, downRightDiff: number) {

  const leftStart = start, upStart = 17.9;

  const maxX = leftStart, minX = leftStart - 18.2;

  const maxY = upStart;
  const minY = upStart - 9.2;

  const minZ = -90;

  const planeGeo = createSquareGeoWithUv(
    [
      [maxX, maxY, minZ],
      [minX, maxY, minZ],
      [maxX + downRightDiff, minY, minZ],

      [minX + downLeftDiff, minY, minZ], //左下
      [maxX + downRightDiff, minY, minZ], //右下
      [minX, maxY, minZ],
    ],
    [
      [0.0, 1.0],
      [1.0, 1.0],
      [0.0, 0.0],

      [1.0, 0.0],
      [0.0, 0.0],
      [1.0, 1.0],
    ]
  );

  planeGeo.rotateX(-MathUtils.degToRad(46));
  // planeGeo.rotateZ(-MathUtils.degToRad(1));

  planeGeo.translate(0, 39, 0);

  return createTexture('texs/test.jpg').then(tex => {
    tex.encoding = 3001;
    const obj3d = new Mesh(planeGeo, new MeshBasicMaterial({ side: FrontSide, map: tex }));

    return obj3d;
  })

}

export const create5leanBoards = () => {

  const step = 27.8

  return Promise.all([
    createLeanBoard(9.5 - step * 2 - 0.7, -0.7, -0.7),
    createLeanBoard(9.5 - step - 0.1, -0.4, -0.4),
    createLeanBoard(9.5, -0.1, 0.1),
    createLeanBoard(9.5 + step, 0.4, 0.4),
    createLeanBoard(9.5 + step * 2, 0.7, 0.7),
  ]
  ).then(objs => new Object3D().add(...objs))
}


export function createStartCompanyBoard() {

}