import { Mesh, MeshPhongMaterial, BufferAttribute, BufferGeometry, DoubleSide, MeshBasicMaterial, BoxGeometry, Object3D, BoxBufferGeometry, ShapeBufferGeometry, BackSide } from "three";
import { FontLoader, Font } from "three/examples/jsm/loaders/FontLoader";
import { createObjectBox3, loadFont, getObj3dBox3Cords,createCube, createSquareGeo } from "./three-utils/utils";




const createText = (message: string) => (font: Font) => {

  const shapes = font.generateShapes(message, 2);
  const geometry = new ShapeBufferGeometry(shapes);
  const color = 0x000000;

  const matLite = new MeshBasicMaterial({
    color: color,
    transparent: false,
    side: DoubleSide
  });

  const text = new Mesh(geometry, matLite);

  text.rotateX(Math.PI);
  text.rotateZ(Math.PI);
  // text.rotateX(Math.PI);

  return text;
}



const setTextOnBoard = (board: Object3D) => (text: Object3D) => {

  const boardBox = createObjectBox3(board);

  text.position.z = boardBox.min.z - 0.02;
  text.position.x = boardBox.max.x - 3;
  text.position.y = boardBox.max.y - 3;

  return text;
}




const createTextBgPlane = (text: Object3D) => {

  const { maxX, maxY, maxZ, minZ, minX, minY } = getObj3dBox3Cords(text);

  const planeGeo = createSquareGeo([
    [maxX, maxY, minZ],
    [minX, maxY, minZ],
    [maxX, minY, minZ],

    [minX, minY, minZ],
    [maxX, minY, minZ],
    [minX, maxY, minZ],
  ]);

  return new Mesh(planeGeo, new MeshBasicMaterial({ color: 'red', side: BackSide }))

}



export const createTextBoard = (fontUrl: string) => {

  const board = createCube('#ddd');

  const msg = `
1. Hello World.
2. Hello World.
3. Hello World.
4. Hello World.
5. Hello World.
6. Hello World.
  `

  return loadFont(fontUrl)
    .then(createText(msg))
    .then(setTextOnBoard(board))
    .then(text => {

      const textBgPlane = createTextBgPlane(text);
      textBgPlane.visible = false;
      return new Object3D().add(board, text, textBgPlane);

    })


}


