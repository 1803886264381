import {
  ColorRepresentation, HemisphereLight, AmbientLight, DirectionalLight, PointLight, PMREMGenerator, WebGLRenderer, UnsignedByteType
} from "three"
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';

export interface HemisphereLightOpts {
  skyColor?: ColorRepresentation | undefined,
  groundColor?: ColorRepresentation | undefined,
  intensity?: number | undefined
}

export const createHemisphereLight = ({ skyColor, groundColor, intensity }: HemisphereLightOpts) => {

  return new HemisphereLight(skyColor, groundColor, intensity)

}


export interface AmbientLightOpt {
  color?: ColorRepresentation | undefined,
  intensity?: number | undefined
}

export const createAmbientLight = ({ color, intensity }: AmbientLightOpt) => {
  return new AmbientLight(color, intensity)
}

export const createDirectionalLight = ({ color, intensity }: AmbientLightOpt) => {
  return new DirectionalLight(color, intensity);
}

export const createPointLight = ({ color, intensity }: AmbientLightOpt) => {
  return new PointLight(color, intensity);
}

export const loadEnvHdr = (renderer: WebGLRenderer) => (envPath: string) => {

  // Recommended path: HDR environments have details revealed by bright and dark reflective surfaces on models.
  var pmremGenerator = new PMREMGenerator(renderer);
  pmremGenerator.compileEquirectangularShader();

  return new RGBELoader().setDataType(UnsignedByteType).loadAsync(envPath)
    .then((texture) => {
      pmremGenerator.dispose();
      return pmremGenerator.fromEquirectangular(texture).texture;
    });

}