


interface IBgData {
  img: string,
  lon: number,
  lat: number
  el: string
}

const bgDatas: IBgData[] = [
  {
    img: "files/panorma-pics/1f_sphere.jpg",
    lon: 180,
    lat: 0,
    el: ".area_box>ul>li:nth-child(1)"
  },
  {
    img: "files/panorma-pics/3f_sphere.jpg",
    lon: 180,
    lat: 0,

    el: ".area_box>ul>li:nth-child(2)"
  },
  {
    img: "files/panorma-pics/4f_sphere.jpg",
    lon: 90,
    lat: 0,

    el: ".area_box>ul>li:nth-child(3)"
  },
  {
    img: "files/panorma-pics/5f_sphere.jpg",
    lon: 90,
    lat: 0,

    el: ".area_box>ul>li:nth-child(4)"
  },
  {
    img: "files/panorma-pics/2f_sphere.jpg",
    lon: -90,
    lat: 0,
    el: ".area_box>ul>li:nth-child(5)"
  },
]


const regisiterBgSwitch = (handleSwitchBg: (bgImg: string, lon: number, lat: number) => void) =>
  (bgData: IBgData) => {

    const el = document.querySelector(bgData.el);

    // console.log('el',el);

    el?.addEventListener('click', (evt) => {
      evt.stopPropagation();
      evt.preventDefault();
      handleSwitchBg(bgData.img, bgData.lon, bgData.lat);
    });

  }

export const regisiterBgArrSwitch = (handleSwitchBg: (bgImg: string, lon: number, lat: number) => void) =>
  bgDatas.forEach(regisiterBgSwitch(handleSwitchBg))

