


import { Raycaster, PerspectiveCamera, Object3D, Event, Intersection } from "three";
import { checkPoint, getFullScreen3dPointByMouseEvt } from "./three-utils/utils";
import { curry } from "lodash/fp";


export const initClickCheck = (
  camera: PerspectiveCamera,
  clickToCheckObj3ds: Object3D[] | (() => Object3D[]),
  onObjectsClick: (results: Intersection<Object3D<Event>>[]) => void
) => {

  const ray = new Raycaster();

  const checkPointFn = curry(checkPoint)(ray, camera);

  const clickHandle = (event: MouseEvent) => {

    const objects = Array.isArray(clickToCheckObj3ds) ? clickToCheckObj3ds : clickToCheckObj3ds();

    const results = checkPointFn(objects, getFullScreen3dPointByMouseEvt(event));

    onObjectsClick(results);

  }

  document.body.addEventListener('click', clickHandle);
  window.addEventListener('unload', () => {
    document.body.removeEventListener('click', clickHandle);
  });

}

