import * as THREE from "three";
import { Mesh, MeshPhongMaterial, MeshBasicMaterial, AnimationMixer, Clock, Event, Color, Intersection, Object3D, Scene } from "three";

import { regisiterBgArrSwitch } from "./handle-panorama-switch";
import { initClickCheck } from "./init-object-click-check";
import { ViewerState } from "./interfaces";
import { loadObjects } from "./loadObjects";
import { loadEnvHdr } from "./three-utils/lights";

const bgImg = "files/panorma-pics/1f_sphere.jpg";

const RADIUS = 1000;
const MOVE_RATIO = 0.1;

export class PanoramaViewer {

  private container: HTMLElement;
  private renderer: THREE.WebGLRenderer;
  private scene: THREE.Scene;
  private scene2: THREE.Scene;

  private camera: THREE.PerspectiveCamera;

  private bgSphere: Mesh;
  private mixer: AnimationMixer | null = null;
  private _enableAnimate = false;
  private clock = new THREE.Clock();

  private lon = 0;
  private lat = 0;

  private _autoRotate = false;

  private marks: { text: string, position: THREE.Vector3 }[] = [];

  private viewerState: ViewerState;

  constructor(container: HTMLElement) {

    this.container = container;

    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(
      75,
      container.clientWidth / container.clientHeight,
      0.01,
      10000
    );

    /** scene */
    const scene = this.scene;
    scene.background = new THREE.Color(0xf0f0f0);
    const renderer = this.renderer;
    renderer.setSize(container.clientWidth, container.clientHeight);

    /** camera */
    const camera = this.camera;

    this.viewerState = {
      clickToCheckObj3ds: [],
      objects: {}
    }

    // 开发用，之后删掉
    // camera.position.set(0, 0, 2);


    this.scene2 = new Scene();

    Promise.resolve('files/hdr/symmetrical_garden_1k.hdr')
      .then(loadEnvHdr(renderer))
      .then(environment => {
        this.scene2.environment = environment
      })


    camera.lookAt(new THREE.Vector3(10, 10, -10));

    initClickCheck(
      this.camera,
      () => this.viewerState.clickToCheckObj3ds,
      this._onObjectClick.bind(this)
    );

    this._loadObjects();


    const sphereGeometry = new THREE.SphereGeometry(RADIUS, 50, 50);
    sphereGeometry.scale(-1, 1, 1);
    const sphereMaterial = new THREE.MeshBasicMaterial({ color: '#fff' });
    const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);

    scene.add(sphere);

    this.bgSphere = sphere;

    this.switchView(bgImg, 180, 0);

    regisiterBgArrSwitch(this.switchView.bind(this));

    /** run! */
    container.appendChild(renderer.domElement);
    this.animate();

    (window as any).viewer = this;

    this._autoRotate = true;


    window.addEventListener("resize", this._onResize.bind(this));

  }



  private _resetCanvasSize() {

    const container = this.container;
    this.renderer.setSize(container.clientWidth, container.clientHeight);
    this.camera.aspect = container.clientWidth / container.clientHeight

  }

  private _onResize() {
    this._resetCanvasSize();
  }

  private _onObjectClick(intersections: Intersection<Object3D<Event>>[]) {

    const checkNode = (object: Object3D, targetNode: Object3D) => {

      const checkObject = (obj3d: Object3D) => obj3d.uuid === targetNode.uuid;

      if (checkObject(object)) {
        return true;
      } else {
        let isMatch = false;
        object.traverseAncestors((upObj) => {
          if (checkObject(upObj)) isMatch = true;
        });
        return isMatch;
      }

    }

    const handelObjectClick = (clickObject: Object3D, girl: Object3D, text: Object3D) => {
      if (checkNode(clickObject, text)) {
        console.log('text clicked');
        window.open('https://www.baidu.com', "_blank");

        this._enableAnimate = false;

      } else if (checkNode(clickObject, girl)) {
        console.log('girl clicked');

        this._enableAnimate = true;
      }
    }

    if (intersections.length > 0) {
      console.log('object clicked checked');


      handelObjectClick(
        intersections[0].object,
        this.viewerState.objects['girl'],
        this.viewerState.objects['text']
      );
    } else {
      this._enableAnimate = false;
    }

  }

  private _loadObjects() {

    return loadObjects(this.scene2, this.viewerState)
    // .then(gltf => {
    //   this.mixer = new AnimationMixer(gltf.scene2);
    //   this.mixer.clipAction(gltf.animations[0]).play();
    // })

  }


  switchView(bgImg: string, lon: number, lat: number) {

    const texture = new THREE.TextureLoader().load(bgImg);
    const sphereMaterial = new THREE.MeshBasicMaterial({ map: texture });

    this.bgSphere.material = sphereMaterial;

    this.lon = lon;
    this.lat = lat;

    this.move(0, 0);
    this.render();

  }


  getViewState() {
    return { lon: this.lon, lat: this.lat }
  }

  teardown() {
    console.log("TODO: implement");
  }

  move(offsetX: number, offsetY: number) {

    const lon = offsetX * MOVE_RATIO + this.lon;
    const lat = Math.max(-85, Math.min(85, offsetY * MOVE_RATIO + this.lat));

    const [phi, theta] = getAngle(lon, lat);
    const newLookat = sphericalToCartesian(phi, theta);

    // console.log('newLookat',newLookat)
    this.camera.lookAt(newLookat);

    this.lat = lat;
    this.lon = lon;

  }

  // TODO: make to module private
  getPoint(clientX: number, clientY: number) {
    const lon = clientX * MOVE_RATIO + this.lon;
    const lat =
      0 - Math.max(-85, Math.min(85, clientY * MOVE_RATIO + this.lat));

    return [lon, lat];
  }

  createSprite(clientX: number, clientY: number, mark: string) {
    const [phi, theta] = this.getPoint(clientX, clientY);
    const position = sphericalToCartesian(phi, theta, 500);

    this.marks.push({
      position,
      text: mark
    })
  }

  private animate() {

    requestAnimationFrame(this.animate?.bind(this));

    if (this._autoRotate) {

      this.lon += 0.06;
      this.move(0, 0);

    }

    this.render();

  }

  render() {
    this.marks.forEach(mark => {
      // TODO: render marks

    })

    const delta = this.clock.getDelta();
    if (this._enableAnimate && this.mixer) this.mixer.update(delta);


    this.renderer.autoClear = true;
    this.renderer.outputEncoding = THREE.LinearEncoding;
    this.renderer.render(this.scene, this.camera);

    this.renderer.autoClear = false;
    this.renderer.outputEncoding = THREE.sRGBEncoding;
    this.renderer.render(this.scene2, this.camera);


  }

}

/** 经纬度坐标系换算成球坐标系角度 */
function getAngle(lon: number, lat: number) {
  const phi = THREE.MathUtils.degToRad(90 - lat);
  const theta = THREE.MathUtils.degToRad(lon);

  return [phi, theta];
}

/** 球坐标到直角坐标系 */
function sphericalToCartesian(phi: number, theta: number, radius = RADIUS) {
  return new THREE.Vector3(
    radius * Math.sin(phi) * Math.cos(theta),
    radius * Math.cos(phi),
    radius * Math.sin(phi) * Math.sin(theta)
  );
}
