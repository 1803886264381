import { AnimationMixer, Color, Object3D, Scene } from "three";
import { createTextBoard } from "./create-text-board";
import { ViewerState } from "./interfaces";
import { createAmbientLight, createHemisphereLight, createPointLight, loadEnvHdr } from "./three-utils/lights";
import { loadGltf, loadModel, transToBoubleSide } from "./three-utils/model";
import { appendChild, getObj3dBox3Cords, hideObj } from "./three-utils/utils";
import { createVideoBoard, createPicsBoards, create5leanBoards, createVideoPoard2, createPicPoard2, createStartComponyBoard as createStartComponyBoard, createCloseComponyBoard as createCloseComponyBoard, createFoodAndDrugBoard, createFoodAndDrugOperaBoard } from "./create-picture-board";

export function loadObjects(scene: Scene, viewerState: ViewerState) {

  // const dl = createPointLight({ color: '#fff', intensity: 1.1 })

  scene
    .add(createHemisphereLight({
      skyColor: new Color('rgb(200,200,200)'),
      groundColor: new Color('rgb(100,100,100)'),
      // intensity:1.1
    }))
  // .add(createAmbientLight({
  //   color: 'rgb(196, 221, 243)',
  //   intensity: 0.5
  // }))
  // .add(dl)

  createTextBoard('fonts/helvetiker_regular.typeface.json')
    .then(obj3d => {

      obj3d.position.z = 95;
      obj3d.position.x = 54;
      obj3d.position.y = 11;

      viewerState.clickToCheckObj3ds = [obj3d.children[2]];
      viewerState.objects['text'] = obj3d;

      const { maxX, maxY, maxZ, minZ, minX, minY } = getObj3dBox3Cords(obj3d);

      console.log(`
      const minX = ${minX};
      const maxX = ${maxX};
      const minY = ${minY};
      const maxY = ${maxY};
      const minZ = ${minZ};
      `)

      return obj3d;
    })
    .then(hideObj)
    .then(appendChild(scene))


  createTextBoard('fonts/helvetiker_regular.typeface.json')
    .then(obj3d => {

      obj3d.position.z = 95;
      obj3d.position.x = 105.4;
      obj3d.position.y = 11;

      return obj3d;
    })
    .then(hideObj)
    .then(appendChild(scene))


  createVideoBoard('blue')
    .then(hideObj)
    .then(appendChild(scene))

  createVideoPoard2()
    .then(hideObj)
    .then(appendChild(scene))

  createPicPoard2()
    .then(hideObj)
    .then(appendChild(scene))

  createPicsBoards('red')
    .then(hideObj)
    .then(appendChild(scene))


  create5leanBoards()
    .then(hideObj)
    .then(appendChild(scene))

  createStartComponyBoard()
    .then(hideObj)
    .then(appendChild(scene))


  createCloseComponyBoard()
    .then(hideObj)
    .then(appendChild(scene))

  createFoodAndDrugBoard()
    .then(hideObj)
    .then(appendChild(scene))

  createFoodAndDrugOperaBoard()
    .then(hideObj)
    .then(appendChild(scene))

  //// load girl model

  // loadModel(
  //   scene,
  //   {
  //     // type: 'gltf',
  //     // basePath: 'files/standup/',
  //     // path: '____gltf.gltf',
  //     // name: 'standup'
  //     type: 'gltf',
  //     basePath: 'files/standup-girl/',
  //     path: 'output.gltf',
  //     name: 'standup-girl'
  //   }
  // )
  //   .then((girl) => {

  //     girl.traverse(child => child.frustumCulled = false);

  //     // console.log('girl', girl);

  //     //// girl.position.y = -1;
  //     // girl.position.y = -1.4;
  //     // girl.position.z = -1.8;
  //     // girl.position.x = -1.3;


  //     girl.position.y = -1.4;
  //     girl.position.z = 0.5;
  //     girl.position.x = -1.5;


  //     girl.rotateY(-Math.PI / 2);

  //     girl.frustumCulled = false;

  //     transToBoubleSide(girl);

  //     const { maxY, maxZ, minX } = getObj3dBox3Cords(girl);

  //     // dl.position.set(0, maxY + 0.1, maxZ + 0.1);

  //     viewerState.objects['girl'] = girl;

  //     return girl

  //   })

  // return Promise.resolve('__.gltf')
  //   .then(loadGltf('files/welcome/'))
  //   .then(gltf => {

  //     console.log('gltf', gltf);

  //     const girl = gltf.scene;

  //     // girl.position.y = -1;

  //     girl.position.y = -1.4;
  //     girl.position.z = -2;
  //     girl.position.x = -1.3;

  //     girl.rotateY(-Math.PI / 2);


  //     const { maxY, maxZ, minX } = getObj3dBox3Cords(girl);
  //     dl.position.set(0, maxY + 0.1, maxZ + 0.1);


  //     viewerState.objects['girl'] = girl;

  //     viewerState.clickToCheckObj3ds.push(girl);

  //     appendChild(scene)

  //     scene.add(girl);

  //     return gltf;

  //   })




}