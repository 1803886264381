import { Box3, Object3D, Event, Raycaster, PerspectiveCamera, BoxBufferGeometry, MeshBasicMaterial, Mesh, BufferGeometry, BufferAttribute, TextureLoader, Texture } from "three";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";


export const createObjectBox3 = (obj3D: Object3D) => new Box3().setFromObject(obj3D);

export const getBox3Cords = (box: Box3) => {
  const { x: minX, y: minY, z: minZ } = box.min;
  const { x: maxX, y: maxY, z: maxZ } = box.max;
  return { minX, minY, minZ, maxX, maxY, maxZ };
};

export const getObj3dBox3Cords = (obj3D: Object3D) => getBox3Cords(createObjectBox3(obj3D));

export const appendChild = (parent: Object3D) => (...object: Object3D<Event>[]) => parent.add(...object);

export const loadFont = (url: string) => {
  const loader = new FontLoader();
  return loader.loadAsync(url)
}

export const getFullScreen3dPointByMouseEvt = (event: MouseEvent) => {
  return {
    x: (event.clientX / window.innerWidth) * 2 - 1,
    y: - (event.clientY / window.innerHeight) * 2 + 1
  }
}

//计算三角形斜边长度
export const calcHypotenuse = (x: number) => (y: number) => Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));


export interface Point { x: number; y: number; }

export const checkPoint = (raycaster: Raycaster, camera: PerspectiveCamera, objects: Object3D<Event>[], pointer: Point) => {
  raycaster.setFromCamera(pointer, camera);
  return raycaster.intersectObjects(objects, true);
}


export const createCube = (color: string) => {

  const box = new BoxBufferGeometry(41, 41, 1);
  const mat = new MeshBasicMaterial({ color: color });

  return new Mesh(box, mat);

}

const createAttribute = (datas: number[][]) => {

  const step = datas[0].length
  var buf = new Float32Array(datas.length * step);

  datas.forEach((subArr, index) => {
    subArr.forEach((num, subIdx) => { buf[index * step + subIdx] = num })
  })

  return new BufferAttribute(buf, step);
}

export const createSquareGeo = (vertexPositions: [number, number, number][]) => {
  return new BufferGeometry().setAttribute('position', createAttribute(vertexPositions));
}

export const createSquareGeoWithUv = (vertexPositions: [number, number, number][], uvs: [number, number][]) => {
  return new BufferGeometry()
    .setAttribute('position', createAttribute(vertexPositions))
    .setAttribute('uv', createAttribute(uvs))

}

export const hideObj = (obj3d: Object3D) => { obj3d.visible = false; return obj3d }

export const createTexture = (img: string): Promise<Texture> => {
  const texLoader = new TextureLoader

  return new Promise((resolve, reject) => {

    const tex = texLoader.load(img, (tex) => {
      // tex.premultiplyAlpha = true;
      resolve(tex);
    });

    // tex.premultiplyAlpha = true;

  });
}